import React, { useState, useEffect, useLayoutEffect, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjekteGridHero from "../components/projekteGridHero"
import Hero from "../components/hero/desktop"
import HeroMobile from "../components/hero/mobile/"
import { isMobile} from "react-device-detect";
import "./index.module.css";
import WhatsNext from "../components/whatsNext/whatsnext";


const IndexPage = () => {
  const [hero, setHero] = useState(<div className="vh-100"></div>);
  const data = useStaticQuery(graphql`
    query {
        ogimage: file(relativePath: { eq: "images/qmarketing-og.jpg" }) {
            childImageSharp {
                fixed(height: 400) {
                    src
                    height
                    width
                }
            }
        }
    }
  `)


  const mobileHero = useMemo(() => {
    return <HeroMobile />
  }, [])
  const desktopHero = useMemo(() => {
    return <Hero />
  }, [])
  useLayoutEffect(() => {
    setHero(isMobile ? mobileHero : desktopHero)
  }, [isMobile, mobileHero, desktopHero]);

  return (
    <Layout>
      <SEO
        title="Agentur für digitale Marketing- und Vertriebslösungen"
        description="Wir sind Ihre Agentur für digitale Marketing- und Vertriebslösungen mit Sitz im Herzen des Ruhrgebiets."
        image={data?.ogimage?.childImageSharp?.fixed}
      />          

      {hero}

      <ProjekteGridHero />

      <WhatsNext text="Projekte &rarr;" link="/projekte"/>      

    </Layout>
  )
}

export default IndexPage