import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import SectionWrap from "../sectionWrap"
import "../layout/layout.css"
import * as whatsnextStyles from "./whatsnext.module.css"
import orangeBack from "../../images/orangeback-01.jpg"

const WhatsNext = (props) => {
    return (      
      // bgImg={orangeBack}      
        <SectionWrap bgCol="#ff6600" className={`${whatsnextStyles.label} container-fluid height25`} pin={false} snap={false}>
          <Link className="hintlink" href={props.link}>
            <div className={`row vcenterRowContent py-5 py-md-0`}>
              <div className="col-12 text-center">
                <div className="margin">
                  <p className="nomargin label">{props.text}</p>
                </div>
              </div>
            </div>
          </Link>
        </SectionWrap>              
    )
}

export default WhatsNext