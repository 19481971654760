import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "../layout/layout.css"
import * as pgStyles from "./projekteGrid.module.css"
import ProjektItem from "../projektItem"

const ProjekteGridHero = (props) => {

  let data = useStaticQuery(graphql`
  query ProjekteGridHeroQuery {
    allProjekteJson(filter: { scope: { eq: "hero" } }) {
      edges {
        node {
          desc
          id
          image {
            childImageSharp {
              gatsbyImageData(          
                placeholder: BLURRED,
                aspectRatio: 1
              )
            }
          }
          link
          linktitle
          logo
          title
          type
          scope
          slug
        }
      }
    }
  }
`)  

  return (
    <>
      <div className={`container-fluid`}>
        <div className={`row ${pgStyles.sectionsetup}`}>
          <div className="container-fluid position-relative">
            <div className="row">
              <div className="col d-flex justify-content-center"><h3 className="pb-3">Projekte entdecken</h3></div>
            </div>
          </div>
          {/* fuer jedes projekt eine kachel  */}
          {data.allProjekteJson.edges.map(({ node }) => (
            <ProjektItem key={node.id} myProjekt={node} />
          ))}
        </div>
      </div>
    </>
  )
}

export default ProjekteGridHero